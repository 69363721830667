import { Link, useLocation } from "react-router-dom";
import { useState, useEffect } from "react";
import { fetchSettings } from "../../services/settingsService";
import close from "../../assets/icons/main/error.png";
import { BarLoader } from "react-spinners";
import btnHamburger from "../../assets/icons/main/btnHamburger.png";
import { NavLink } from "react-router-dom";

const Navbar = () => {
  const { pathname } = useLocation();
  const [showSideMenu, setShowSideMenu] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const [settings, setSettings] = useState([]);
  const [loadingSettings, setLoadingSettings] = useState(false);
  const [errorSettings, setErrorSettings] = useState(null);

  useEffect(() => {
    setLoadingSettings(true);
    fetchSettings()
      .then((response) => {
        setSettings(response.data.data);
        setLoadingSettings(false);
      })
      .catch((error) => {
        setErrorSettings("An error occurred while fetching settings");
        setLoadingSettings(false);
      });
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY >= 50);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  if (loadingSettings) {
    return (
      <div className="loader-container">
        <BarLoader color="#FFDD45" loading={loadingSettings} />
      </div>
    );
  }

  if (errorSettings) {
    return <div>Error: {errorSettings}</div>;
  }

  return (
    <header
      className={`header ${isScrolled ? "fixed" : ""} ${
        pathname === "/influencer" ||
        pathname === "/services" ||
        pathname === "/404"
          ? "black"
          : ""
      }`}
      id={showSideMenu ? "show" : ""}
    >
      <div className="header_container container">
        <div className="mobile_btn_open">
          <img
            onClick={() => setShowSideMenu(!showSideMenu)}
            src={btnHamburger}
            alt="btn-hamburger"
          />
        </div>

        <Link to="/" className="logo_container">
          <img src={settings["site.logo"]} alt="main-logo" />
        </Link>

        <nav className="nav_container">
          <ul>
            <li>
              <NavLink
                exact
                to="/"
                activeClassName="active"
                className="nav_container_item"
              >
                Home
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/services"
                activeClassName="active"
                className="nav_container_item"
              >
                Services
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/influencer"
                activeClassName="active"
                className="nav_container_item"
              >
                Influencers
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/about"
                activeClassName="active"
                className="nav_container_item"
              >
                About
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/contact"
                activeClassName="active"
                className="nav_container_item"
              >
                Contact
              </NavLink>
            </li>
          </ul>
        </nav>

        <div className="contact_container mobile_hidden">
          <Link to={settings["site.telegram_link"]}>
            <i className="fa-brands fa-telegram"></i>
          </Link>
        </div>
      </div>

      <div className="sidebar">
        <div className="sidebar_header" onClick={() => setShowSideMenu(false)}>
          <span className="close_button">
            <img src={close} alt="error" />
          </span>
        </div>
        <ul className="sidebar_body">
          <li>
            <NavLink
              exact
              to="/"
              activeClassName="active"
              onClick={() => setShowSideMenu(false)}
            >
              Home
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/services"
              activeClassName="active"
              onClick={() => setShowSideMenu(false)}
            >
              Services
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/influencer"
              activeClassName="active"
              onClick={() => setShowSideMenu(false)}
            >
              Influencer
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/about"
              activeClassName="active"
              onClick={() => setShowSideMenu(false)}
            >
              About
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/contact"
              activeClassName="active"
              onClick={() => setShowSideMenu(false)}
            >
              Contact
            </NavLink>
          </li>
        </ul>
      </div>
    </header>
  );
};

export default Navbar;
