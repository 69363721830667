import React from "react";
import "aos/dist/aos.css";
import CountUp from "react-countup";
import bitcoin from "../../assets/icons/coins/bitcoin.png";
import ethereum from "../../assets/icons/coins/ethereum.png";

const Amount = () => {
  return (
    <div className="services-amount-body">
      <div className="container">
        <div className="services-amount-wrapper">
          <div className="item-container" data-aos="fade-up">
            <h3>
              <CountUp end={10} duration={5} />
              <p>+</p>
            </h3>
            <p>Daily Video</p>
          </div>

          <div className="item-container" data-aos="fade-up">
            <h3>
              <CountUp end={1150} duration={5} />
              <p>+</p>
            </h3>
            <p>Hourly view</p>
          </div>

          <div className="item-container" data-aos="fade-up">
            <h3>
              <CountUp end={570} duration={5} />
              <p>+</p>
            </h3>
            <p>Satisfied Clients</p>
          </div>

          <div className="item-container" data-aos="fade-up">
            <h3>
              <CountUp end={260000} duration={5} />
              <p>+</p>
            </h3>
            <p>Subscriber</p>
          </div>
        </div>
        <div className="bitcoin_wrapper">
          <img src={bitcoin} alt="bitcoin_img" />
        </div>
        <div className="ethereum_wrapper">
          <img src={ethereum} alt="ethereum_img" />
        </div>
      </div>
    </div>
  );
};

export default Amount;
