import React, { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import {
  fetchInfluencerStatistics,
  fetchInfluencers,
} from "../../services/influencersService";
import { BarLoader } from "react-spinners";
import { Helmet } from "react-helmet";

function Statistics() {
  const [influencers, setInfluencers] = useState([]);
  const [newObjectsWithoutInternal, setNewObjectsWithoutInternal] = useState(
    []
  );
  const [loadingInfluencers, setLoadingInfluencers] = useState(true);
  const [errorInfluencers, setErrorInfluencers] = useState(null);

  const { id } = useParams();
  const [influencerStatistics, setInfluencerStatistics] = useState({});
  const [loadingInfluencerStatistics, setLoadingInfluencerStatistics] =
    useState(true);
  const [errorInfluencerStatistics, setErrorInfluencerStatistics] =
    useState(null);

  useEffect(() => {
    setLoadingInfluencers(true);
    fetchInfluencers()
      .then((response) => {
        const fetchedInfluencers = response.data.data;
        setInfluencers(fetchedInfluencers);

        const filteredInfluencers = fetchedInfluencers.filter(
          (item) => item.id !== parseInt(id)
        );
        setNewObjectsWithoutInternal(filteredInfluencers);

        setLoadingInfluencers(false);
      })
      .catch((error) => {
        setErrorInfluencers("An error occurred while fetching influencers");
        setLoadingInfluencers(false);
      });
  }, [id]);

  useEffect(() => {
    setLoadingInfluencerStatistics(true);
    fetchInfluencerStatistics(id)
      .then((response) => {
        setInfluencerStatistics(response.data.data);
        setLoadingInfluencerStatistics(false);
      })
      .catch((error) => {
        setErrorInfluencerStatistics(
          "An error occurred while fetching statistics"
        );
        setLoadingInfluencerStatistics(false);
      });
  }, [id]);

  if (loadingInfluencerStatistics || loadingInfluencers) {
    return (
      <div className="loader-container">
        <BarLoader
          color="#FFDD45"
          loading={loadingInfluencerStatistics || loadingInfluencers}
        />
      </div>
    );
  }

  if (errorInfluencerStatistics || errorInfluencers) {
    return <div>Error: {errorInfluencerStatistics || errorInfluencers}</div>;
  }

  return (
    <>
      <Helmet>
        <title>{`cryptofast | ${influencerStatistics?.name}` || "Title"}</title>
        <meta
          name="description"
          content={"influencers page" || "Description"}
        />
        <meta name="keywords" content={"influencers page" || "Keywords"} />
      </Helmet>

      <section className="statistics-page">
        <div className="container">
          <div className="title-container" data-aos="zoom-in">
            <h1>Statistics</h1>
            <div className="line_element"></div>
          </div>

          <div className="main-content">
            <div className="main-content-left" data-aos="fade-right">
              <div key={influencerStatistics?.id} className="influencer-item">
                <div
                  className="influencer-item-image"
                  style={{
                    backgroundImage: `url(${influencerStatistics?.image})`,
                  }}
                ></div>

                <div className="influencer-item-content">
                  <p className="name">{influencerStatistics?.name}</p>
                  <div className="count">
                    <span>{influencerStatistics?.sub_count}</span>
                    <p>Subscribers</p>
                  </div>

                  <div className="buttons">
                    <Link
                      to={influencerStatistics?.channel_link}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <button>Go to channel</button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>

            <div className="main-content-right" data-aos="fade-left">
              <div className="statistics-wrapper">
                <div className="statistics-contents">
                  <ul>
                    {influencerStatistics?.influencer_country_statistics
                      ?.length > 0 ? (
                      influencerStatistics.influencer_country_statistics.map(
                        (stats) => (
                          <li key={stats.id}>
                            <div className="country-wrapper">
                              <img
                                src={stats.country.image}
                                alt={stats.country.name}
                              />
                              <span>{stats.country.name}</span>
                            </div>
                            <p>%{Math.floor(stats.percent)}</p>
                          </li>
                        )
                      )
                    ) : (
                      <p>No data available :(</p>
                    )}
                  </ul>
                </div>

                <div className="statistics-contents">
                  <ul>
                    {influencerStatistics?.influencer_age_statistics?.length >
                    0 ? (
                      influencerStatistics.influencer_age_statistics.map(
                        (stats) => (
                          <li key={stats.id}>
                            <span>{stats.name}</span>
                            <p>%{Math.floor(stats.percent)}</p>
                          </li>
                        )
                      )
                    ) : (
                      <p>No data available :(</p>
                    )}
                  </ul>
                </div>

                <div className="statistics-contents">
                  <ul>
                    {influencerStatistics?.influencer_gender_statistics
                      ?.length > 0 ? (
                      influencerStatistics.influencer_gender_statistics.map(
                        (stats) => (
                          <li key={stats.id}>
                            <span>{stats.name}</span>
                            <p>%{Math.floor(stats.percent)}</p>
                          </li>
                        )
                      )
                    ) : (
                      <p>No data available :(</p>
                    )}
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div className="other-influencers">
            <div className="title-container" data-aos="zoom-in">
              <h1>Other influencers</h1>
              <div className="line_element"></div>
            </div>
            <div className="other-influencers-wrapper">
              {Array.isArray(newObjectsWithoutInternal) &&
                newObjectsWithoutInternal.map((influencer) => (
                  <div
                    key={influencer?.id}
                    className="other-influencers-wrapper-item"
                    data-aos="fade-up"
                  >
                    <div
                      className="other-influencers-wrapper-item-image"
                      style={{
                        backgroundImage: `url(${influencer?.image})`,
                      }}
                    ></div>

                    <div className="other-influencers-wrapper-item-content">
                      <p className="name">{influencer?.name}</p>
                      <div className="count">
                        <span>{influencer?.sub_count}</span>
                        <p>Subscribers</p>
                      </div>

                      <div className="buttons">
                        <Link to={`/influencer/${influencer?.id}`}>
                          Statistic
                        </Link>

                        <Link
                          to={influencer?.channel_link}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Go to channel
                        </Link>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Statistics;
