import suitable from "../../assets/icons/solutions/suitable.png";
import live_support from "../../assets/icons/solutions/live_support.png";
import high_quality from "../../assets/icons/solutions/high_quality.png";
import crypto_fast_board from "../../assets/images/solutions/crypto-fast-board.png";

const Solutions = () => {
  return (
    <>
      <section className="section-information">
        <div className="container">
          <div className="content">
            <div className="content-left">
              <div data-aos="zoom-in">
                <span>Start To Grow</span>
                <h2>
                  -We provide full range marketing solutions in crypto-market
                </h2>
              </div>

              <ul>
                <li data-aos="fade-down">
                  <img src={suitable} alt="suitable" />
                  <span>Suitable price</span>
                </li>

                <li data-aos="fade-down">
                  <img src={live_support} alt="live-support" />
                  <span>Live support</span>
                </li>

                <li data-aos="fade-down">
                  <img src={high_quality} alt="live-support" />
                  <span>High quality content</span>
                </li>
              </ul>
            </div>

            <div className="content-right" data-aos="fade-left">
              <img src={crypto_fast_board} alt="crypto-fast-board" />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Solutions;
