import React from "react";
import "aos/dist/aos.css";
import CountUp from "react-countup";

const AmountMain = () => {
  return (
    <>
      <section className="section-amount-main">
        <div className="container">
          <div className="content">
            <div className="content-item" data-aos="fade-right">
              <p>
                <CountUp end={350} duration={5} />
                <span>+</span>
              </p>
              <p>Videos released monthly</p>
            </div>

            <div className="content-item" data-aos="fade-left">
              <p>
                <CountUp end={570} duration={5} />
                <span>+</span>
              </p>
              <p>Projects worked with us</p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AmountMain;
