import telegram from "../../assets/icons/social-network/telegram.png";
import { Link } from "react-router-dom";

const CryptoAgents = ({ agentsContacts }) => {
  return (
    <>
      <section className="section-agents-contacts">
        <div className="title-container" data-aos="zoom-in">
          <h2>Our agents on telegram</h2>
          <div className="line_element"></div>
        </div>

        <div className="agents-contacts">
          <div className="agents-contacts-item" data-aos="fade-up">
            <Link to={agentsContacts["site.contact.telegram.1"]}>
              <img src={telegram} alt="telegram-icon"></img>
              <span>@CryptoFastYT</span>
            </Link>
          </div>

          <div className="agents-contacts-item" data-aos="fade-up">
            <Link to={agentsContacts["site.contact.telegram.2"]}>
              <img src={telegram} alt="telegram-icon"></img>
              <span>@CryptoFastManager</span>
            </Link>
          </div>

          <div className="agents-contacts-item" data-aos="fade-up">
            <Link to={agentsContacts["site.contact.telegram.3"]}>
              <img src={telegram} alt="telegram-icon"></img>
              <span>@CryptoFastYoutube</span>
            </Link>
          </div>
        </div>
      </section>
    </>
  );
};

export default CryptoAgents;
