import ethereum_benefit from "../../assets/icons/coins/ethereum_benefit.png";
import bitcoin_benefit from "../../assets/icons/coins/bitcoin_benefit.png";
const Benefits = () => {
  return (
    <>
      <section className="section-benefits">
        <div className="container">
          <div className="content">
            <div className="ethereum_wrapper">
              <img src={ethereum_benefit} alt="ethereum_img_benefit"></img>
            </div>

            <div className="bitcoin_wrapper">
              <img src={bitcoin_benefit} alt="bitcoin_img_benefit"></img>
            </div>

            <div className="info-text" data-aos="zoom-in">
              <span>What we do?</span>

              <div className="headingExplanation">
                <h3>-Deal step by step</h3>
              </div>
            </div>

            <div className="body">
              <div className="item" data-aos="fade-up">
                <h2>Planning and preparation</h2>
                <div className="line_element"></div>
                <p>
                  We arrange cooperation, assign a project team to you, and
                  together create a list of necessary requirements to achieve
                  goals and KPIs of the ad campaign
                </p>
              </div>

              <div className="item" data-aos="fade-up">
                <h2>Influencers search</h2>
                <div className="line_element"></div>
                <p>
                  We search for influencers that match your criteria, negotiate
                  with them, and come to the best terms for your product ad
                </p>
              </div>

              <div className="item" data-aos="fade-up">
                <h2>Approval</h2>
                <div className="line_element"></div>
                <p>
                  You select preferred influencers to work with, confirm the
                  terms of reference and the publication deadlines, as well as
                  draft ad videos
                </p>
              </div>

              <div className="item" data-aos="fade-up">
                <h2>Contracts and publication</h2>
                <div className="line_element"></div>
                <p>
                  We handle all of the legal paperwork with selected influencers
                  and control launch timing and compliance with the approved
                  draft
                </p>
              </div>

              <div className="item" data-aos="fade-up">
                <h2>Reports and payments</h2>
                <div className="line_element"></div>
                <p>
                  We only use one account for payment and offer secure payment
                  option. We are regulated by the state and we pay taxes.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Benefits;
