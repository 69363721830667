import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { fetchService } from "../../services/servicesService";
import { BarLoader } from "react-spinners";
import Amount from "../../сomponents/sections/Amount";
import { Helmet } from "react-helmet";

const Services = () => {
  const [services, setServices] = useState([]);
  const [loadingServices, setLoadingServices] = useState(true);
  const [errorServices, setErrorServices] = useState(null);

  useEffect(() => {
    fetchService()
      .then((response) => {
        setServices(response.data.data);
        setLoadingServices(false);
      })
      .catch((error) => {
        setErrorServices("An error occurred while fetching services");
        setLoadingServices(false);
      });
  }, []);

  if (loadingServices) {
    return (
      <div className="loader-container">
        <BarLoader color="#FFDD45" loading={loadingServices} />
      </div>
    );
  }

  if (errorServices) {
    return (
      <div className="error-container">
        <h2>Error: {errorServices}</h2>
        <p>
          The requested services could not be loaded. Please try again later.
        </p>
        <Link to="/">Return to Home</Link>
      </div>
    );
  }

  return (
    <>
      <Helmet>
        <title>{"cryptofast | services" || "Title"}</title>
        <meta name="description" content={"services page" || "Description"} />
        <meta name="keywords" content={"services page" || "Keywords"} />
      </Helmet>

      <section className="services-page">
        <div className="container">
          <div className="title-container" data-aos="zoom-in">
            <h1>Promotion Service</h1>
            <div className="line_element"></div>
            <p>CF CRYPTO MARKETING AGENCY SERVICES WE OFFER</p>
          </div>

          <div className="main-content">
            {Array.isArray(services) &&
              services.map((service) => (
                <div
                  className="service-card"
                  data-aos="fade-up"
                  key={service?.id}
                >
                  {service.image.length > 1 ? (
                    void ""
                  ) : (
                    <i className={service.icon_class}></i>
                  )}
                  {service.image.length > 1 ? (
                    <img src={service?.image} alt={service?.title} />
                  ) : (
                    <span></span>
                  )}
                  <h2>{service?.title}</h2>
                  <div className="line_element"></div>
                  <div
                    dangerouslySetInnerHTML={{ __html: service?.content }}
                  ></div>
                </div>
              ))}
          </div>
        </div>
        <Amount />
      </section>
    </>
  );
};

export default Services;
