import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { BarLoader } from "react-spinners";
import { fetchInfluencers } from "../../services/influencersService";
import Amount from "../../сomponents/sections/Amount";
import { Helmet } from "react-helmet";

const Influencer = () => {
  const [influencers, setInfluencers] = useState({});
  const [loadingInfluencers, setLoadingInfluencers] = useState(true);
  const [errorInfluencers, setErrorInfluencers] = useState(null);

  useEffect(() => {
    setLoadingInfluencers(true);
    fetchInfluencers()
      .then((response) => {
        setInfluencers(response.data.data);
        setLoadingInfluencers(false);
      })
      .catch((error) => {
        setErrorInfluencers("An error occurred while fetching influencers");
        setLoadingInfluencers(false);
      });
  }, []);

  if (loadingInfluencers) {
    return (
      <div className="loader-container">
        <BarLoader
          size={25}
          color={"#ffdd45"}
          loading={loadingInfluencers}
        />
      </div>
    );
  }

  if (errorInfluencers) {
    return <div>Error: {errorInfluencers}</div>;
  }

  return (
    <>
      <Helmet>
        <title>{"cryptofast | influencers" || "Title"}</title>
        <meta name="description" content={"influencers page" || "Description"} />
        <meta name="keywords" content={"influencers page" || "Keywords"} />
      </Helmet>

      <section className="influencers-page">
        <div className="container">
          <div className="title-container" data-aos="zoom-in">
            <h1>Influencers</h1>
            <div className="line_element"></div>
          </div>

          <div className="influencers-wrapper">
            {Array.isArray(influencers) &&
              influencers.map((influencer) => (
                <div
                  key={influencer?.id}
                  className="influencer-item"
                  data-aos="fade-up"
                >
                  <div
                    className="influencer-item-image"
                    style={{
                      backgroundImage: `url(${influencer?.image})`,
                    }}
                  ></div>

                  <div className="influencer-item-content">
                    <p className="name">{influencer?.name}</p>
                    <div className="count">
                      <span>{influencer?.sub_count}</span>
                      <p>Subscribers</p>
                    </div>

                    <div className="buttons">
                      <Link to={`/influencer/${influencer?.id}`}>
                        Statistic
                      </Link>

                      <Link
                        to={influencer?.channel_link}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Go to channel
                      </Link>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
        <Amount />
      </section>
    </>
  );
};

export default Influencer;
