import { useEffect } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

import ethereum_icon_2 from "../../assets/icons/hero/ethereum-2.png";

import bitcoin_icon from "../../assets/icons/hero/bitcoin.png";
import ethereum_icon from "../../assets/icons/hero/ethereum.png";

const NotFound = () => {
  return (
    <>
      <Helmet>
        <title>{"cryptofast | 404" || "Title"}</title>
        <meta name="description" content={"404 page" || "Description"} />
        <meta name="keywords" content={"404 page" || "Keywords"} />
      </Helmet>

      <section className="section-not-found">
        <div className="container">
          <div className="error-body">
            {/* ICONS */}
            <div className="ethereum-wrapper">
              <img src={ethereum_icon} alt="ethereum-icon"></img>
            </div>

            <div className="bitcoin-wrapper">
              <img src={bitcoin_icon} alt="bitcoin-icon"></img>
            </div>

            <h1>404</h1>
            <p>Sorry, we were unable to find that page</p>
            <Link className="btn-return" to="/">
              Home Page
            </Link>

            <div className="ethereum-wrapper-2">
              <img src={ethereum_icon_2} alt="ethereum-icon"></img>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default NotFound;