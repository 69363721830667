import youtube_icon from "../../assets/icons/hero/youtube.png";
import bitcoin_icon from "../../assets/icons/hero/bitcoin.png";
import ethereum_icon from "../../assets/icons/hero/ethereum.png";
import ethereum_icon_2 from "../../assets/icons/hero/ethereum-2.png";
import { Link } from "react-router-dom";

const Hero = () => {
  return (
    <>
      <section className="section-hero">
        <div className="container">
          <div className="content">
            <div data-aos="fade-up">
              <h1>The Best Crypto Agency in The World</h1>
              <p>
                Promote your crypto & NFT projects with PR's, advertorials,
                telegram groups on the biggest crypto news and influencers
                channels now!
              </p>
              <Link to="/contact">Contact Us</Link>
            </div>
          </div>
          {/* YOUTUBE */}
          <div className="youtube-wrapper-1 mobile-hidden-2" data-aos="zoom-in">
            <img src={youtube_icon} alt="youtube-icon"></img>
          </div>
          <div className="youtube-wrapper-2 mobile-hidden-2" data-aos="zoom-in">
            <img src={youtube_icon} alt="youtube-icon"></img>
          </div>
          <div className="youtube-wrapper-3 mobile-hidden-2" data-aos="zoom-in">
            <img src={youtube_icon} alt="youtube-icon"></img>
          </div>
          {/* BITCOIN */}
          <div className="bitcoin-wrapper-1" data-aos="zoom-in">
            <img src={bitcoin_icon} alt="bitcoin-icon"></img>
          </div>
          <div className="bitcoin-wrapper-2 mobile-hidden-2" data-aos="zoom-in">
            <img src={bitcoin_icon} alt="bitcoin-icon"></img>
          </div>
          {/* ETHEREUM */}
          <div
            className="ethereum-wrapper-1 mobile-hidden-2"
            data-aos="zoom-in"
          >
            <img src={ethereum_icon} alt="ethereum-icon"></img>
          </div>
          <div
            className="ethereum-wrapper-2 mobile-hidden-2"
            data-aos="zoom-in"
          >
            <img src={ethereum_icon} alt="ethereum-icon"></img>
          </div>
          {/* ETHEREUM-2*/}
          <div className="ethereum-wrapper-second-1" data-aos="zoom-in">
            <img src={ethereum_icon_2} alt="ethereum-icon-second-1"></img>
          </div>
          <div
            className="ethereum-wrapper-second-2 mobile-hidden-2"
            data-aos="zoom-in"
          >
            <img src={ethereum_icon_2} alt="ethereum-icon-second-2"></img>
          </div>
        </div>
      </section>
    </>
  );
};

export default Hero;
