import telegram from "../../assets/icons/social-network/telegram.png";

import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { fetchSettings } from "../../services/settingsService";

const Footer = () => {
  const [settings, setSettings] = useState({});
  const [loadingSettings, setLoadingSettings] = useState(false);
  const [errorSettings, setErrorSettings] = useState(null);

  // Fetching settings
  useEffect(() => {
    setLoadingSettings(true);
    fetchSettings()
      .then((response) => {
        setSettings(response.data.data);
        setLoadingSettings(false);
      })
      .catch((error) => {
        setErrorSettings("An error occurred while fetching settings");
        setLoadingSettings(false);
      });
  }, []);

  // Browser state
  if (loadingSettings) return <div>Loading...</div>;
  if (errorSettings) return <div>Error: {errorSettings}</div>;

  return (
    <footer className="footer">
      <div className="container">
        <div className="footer-content">
          <div className="logo-container">
            <img src={settings["site.logo"]} alt="logo" />
            <div className="footer-short-info">
              {settings["site.footer_description"]}
            </div>
          </div>

          <div className="footer-contact">
            <div className="contact-item-info">
              <h3>Contact us:</h3>
              <Link to={settings["site.email"]}>
                <span>Email:</span>
                {settings["site.email"]}
              </Link>
            </div>

            <div className="footer-social">
              <Link to={settings["site.telegram_link"]}>
                <img src={telegram} alt="telegram-icon"></img>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
