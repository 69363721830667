import { useEffect } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import NotFound from "./pages/404";
import About from "./pages/about";
import Contact from "./pages/contact";
import Home from "./pages/home";
import Influencer from "./pages/influencer";
import Services from "./pages/services";
import "../src/styles/main.css";
import Statistics from "./pages/influencer/statistics";
import ScrollToTop from './сomponents/ScrollToTop';
import AOS from "aos";
import "aos/dist/aos.css";

function App() {
  useEffect(() => {
    AOS.init({ duration: 1500 });
  }, []);

  return (
    <>
      <main className="app">
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/influencer" element={<Influencer />} />
          <Route path="/influencer/:id" element={<Statistics />} />
          <Route path="/services" element={<Services />} />
          <Route path="/404" element={<NotFound />} />
          <Route path="*" element={<Navigate to="/404" replace />} />
        </Routes>
      </main>
    </>
  );
}

export default App;