// COMPONENTS
import Hero from "../../сomponents/sections/Hero";
import Benefits from "../../сomponents/sections/Benefits";
import AmounMain from "../../сomponents/sections/AmountMain";
import Solutions from "../../сomponents/sections/Solutions";
import Partners from "../../сomponents/sections/Partners";
import Testimonial from "../../сomponents/sections/Testimonial";
import { Helmet } from "react-helmet";

const Home = () => {
  return (
    <>
      <Helmet>
        <title>{"cryptofast | home" || "Title"}</title>
        <meta name="description" content={"home page" || "Description"} />
        <meta name="keywords" content={"home page" || "Keywords"} />
      </Helmet>

      <Hero />
      <Benefits />
      <Solutions />
      <AmounMain />
      <Testimonial />
      <Partners />
    </>
  );
};

export default Home;
