import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { fetchPagesOne } from "../../services/pagesService";
import AOS from "aos";
import { Helmet } from "react-helmet";
import "aos/dist/aos.css";
import { BarLoader } from "react-spinners";

const About = () => {
  useEffect(() => {
    AOS.init({ duration: 1500 });
  }, []);
  const { pathname } = useLocation();
  const [aboutData, setPages] = useState({});
  const [loadingPages, setLoadingPages] = useState(true);
  const [errorPages, setErrorPages] = useState(null);
  useEffect(() => {
    AOS.init({ duration: 1500 });
    setLoadingPages(true);
    fetchPagesOne(pathname)
      .then((response) => {
        setPages(response.data.data);
        setLoadingPages(false);
      })
      .catch((error) => {
        setErrorPages("An error occurred while fetching pages");
        setLoadingPages(false);
      });
  }, [pathname]);

  if (loadingPages) {
    return (
      <div className="loader-container">
        <BarLoader
          size={25}
          color={"#ffdd45"}
          loading={loadingPages}
        />
      </div>
    );
  }

  if (errorPages) {
    return <div>Error: {errorPages}</div>;
  }

  return (
    <>
      <Helmet>
        <title>{aboutData?.meta_title || "Title"}</title>
        <meta
          name="description"
          content={aboutData?.meta_description || "Description"}
        />
        <meta
          name="keywords"
          content={aboutData?.meta_keywords || "Keywords"}
        />
      </Helmet>

      <section className="about-page">
        <div className="container">
          <div className="title-container" data-aos="zoom-in">
            <h1>{aboutData?.title_header}</h1>
            <div className="line_element"></div>
          </div>

          <div className="about-page-body">
            <div data-aos="zoom-in-up">
              <img src={aboutData?.thumbnail_image} alt="about" />
            </div>

            <div className="main-content-wrapper" data-aos="zoom-in-up">
              <p>{aboutData?.title_content}</p>
              <div
                className="main-content"
                dangerouslySetInnerHTML={{ __html: aboutData?.content }}
              ></div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default About;
